import React, { Component } from 'react'
import { graphql } from 'gatsby'
// import { MediaQueryProvider, MediaQuery } from 'react-responsive-hoc'
import { connect } from 'react-redux'
import MainLayout from '../templates/MainLayout'
import CenterOverlay from '../components/CenterOverlay/CenterOverlay'
import IntroLargeTitle from '../components/IntroLargeTitle/IntroLargeTitle'
import SectionTitle from '../components/SectionTitle/SectionTitle'
import Lead from '../components/Lead/Lead'
import LinkedRooms from '../components/LinkedRooms/LinkedRooms'

const translations = [
  {
    path: '/',
    native_name: 'Español'
  },
  {
    path: '/en/grau-roig/',
    native_name: 'English'
  },
  {
    path: '/ca/grau-roig/',
    native_name: 'Català'
  },
  {
    path: '/fr/grau-roig/',
    native_name: 'Français'
  }
]

class NotFoundPage extends Component {
  componentDidMount () {
    const locale = 'es'
    const langData = {
      locale,
      translations
    }
    this.props.setLangData(langData)
  }

  render () {
    const bookContent = this.props.data.bookContent
    const linkedRooms = this.props.data.mainHeader.megamenu.linked_rooms
    return (
      <MainLayout
        mainHeaderData={this.props.data.mainHeader}
        bookContent={bookContent}
        locale='es'
        locationKey={''}
        translations={translations}
      >
        <CenterOverlay>
          <IntroLargeTitle text='No hemos podido encontrar la página…' />
          <Lead text='404' centered />
          <LinkedRooms items={linkedRooms} locale='es' />
        </CenterOverlay>{' '}
      </MainLayout>
    )
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setLangData: langData =>
      dispatch({
        type: `LANG_DATA`,
        langData
      })
  }
}

export default connect(
  null,
  mapDispatchToProps
)(NotFoundPage)

export const query = graphql`
  query notFoundPageQuery {
    mainHeader: wordpressMainHeader(language_code: { eq: "es" }) {
      ...MainHeaderContent
    }
    bookContent: wordpressStickyBook(language_code: { eq: "es" }) {
      ...StickyBookContent
    }
  }
`
